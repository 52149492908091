const PROJECTS = [
    {
        "id": "14",
        "type": "Web Development",
        "title": "Visit Pearl (Under Development)",
        "description": "Visit Pearl is a travel agency that provides various travel packages for tourists. The website is developed using React JS, Node JS, Azure Functions, Azure SQL Databases, Azure SQL Server, Azure Virtual Network, Azure Blob Storage, Azure App Service, Azure CDN, Azure Traffic Manager, Azure DNS, Azure Front Door, Azure API Gateway, Azure Key Vault, Azure DevOps, Azure Pipelines. This project is still under development.",
        "client": "VisitPearl.com",
        "countryFlag": "https://www.countryflags.com/wp-content/uploads/flag-jpg-xl-9-2048x1024.jpg",
        "country": "Australia",
        "date": "2024",
        "technologies": [
            "React JS",
            "Node JS",
            "Azure Active Directory",
            "Azure Functions",
            "Azure SQL Databases",
            "Azure SQL Server",
            "Azure Virtual Network",
            "Azure Blob Storage",
            "Azure App Service",
            "Azure CDN",
            "Azure Traffic Manager",
            "Azure DNS",
            "Azure Front Door",
            "Azure API Gateway",
            "Azure Key Vault",
            "Azure DevOps",
            "Azure Pipelines",
            "Google Maps API",
            "Google Places API",
            "Flight API",
        ],
        "url": "https://visitpearl.com.au/",
        "images": [
            "https://i.ibb.co/vVxL8XS/vp1.png",
            "https://i.ibb.co/rdSrQBH/vp2.png",
            "https://i.ibb.co/PYXmfT1/vp3.png",
            "https://i.ibb.co/rM2hZ9N/vp4.png",
            "https://i.ibb.co/G2JLJgG/vp5.png",
            "https://i.ibb.co/YDVfXWV/vp6.png",
            "https://i.ibb.co/5R0Zs4L/vp7.png",
            "https://i.ibb.co/hHtXHNv/vp8.png",
            " https://i.ibb.co/C0FHtQ1/vp9.png"
        ],
        "xs": 12
    },
    {
        "id": "16",
        "type": "Fullstack Development",
        "title": "Labor Friends of Israel (Custom Form Plugin) - Australia",
        "description": "Labor Friends of Israel are dedicated to fostering strong connections between the Australian Labour movement and the state of Israel. Our mission is to promote understanding, and encourage collaboration between Australia and Israel. I have developed a custom form plugin for this project using PHP, WordPress Shortcodes, Elementor.",
        "client": "Orion Soft",
        "countryFlag": "https://www.countryflags.com/wp-content/uploads/flag-jpg-xl-9-2048x1024.jpg",
        "country": "Australia",
        "date": "2024",
        "technologies": [
            "PHP",
            "WordPress",
            "Elementor",
            "PHP-MYSQL",
        ],
        "url": null,
        "images": [
            "https://i.ibb.co/dWctrfY/lfi.png"
        ],
        "xs": 4
    },
    {
        "id": "15",
        "type": "Web Development",
        "title": "Silicon Surge",
        "description": "Silicon Surge is a vibrant and inventive IT services firm established by three experienced industry experts with a combined 35 years of experience at IBM. Specializing in offering customized IT solutions to adapt to the changing requirements of businesses in diverse sectors, our company concentrates on managed services, software, networking, and telecommunications (Telco).",
        "client": "Silicon Surge (Pvt) Ltd.",
        "countryFlag": "https://www.countryflags.com/wp-content/uploads/indonesia-flag-png-large.png",
        "country": "Indonesia",
        "date": "2024",
        "technologies": [
            "React JS",
            "Node JS",
        ],
        "url": "https://www.silicon-surge.com/",
        "images": [
            "https://i.ibb.co/X22mC3b/siliconsurge1.png",
            "https://i.ibb.co/TkdsZyf/siliconsurge2.png",
            "https://i.ibb.co/5YPKcgb/siliconsurge3.png"
        ],
        "xs": 8
    },
    {
        "id": "5",
        "type": "Fullstack Development",
        "title": "Exotic Technologies - Australia",
        "description": "Exotic Technologies is a e-commerce store that focuses on IT related products. The backend fully automates the process of managing products, orders, customers, inventory, etc. Exotic Technologies Backend is act as a middleware between Exotic Technologies store and the supplier's store. The backend is fully serverless and it is hosted on AWS. The backend is developed using AWS Lambda, AWS S3, AWS DynamoDB, Shopify API, AWS API Gateway.",
        "client": "Exotic Technologies",
        "countryFlag": "https://www.countryflags.com/wp-content/uploads/flag-jpg-xl-9-2048x1024.jpg",
        "country": "Australia",
        "date": "2024",
        "technologies": [
            "AWS Lambda",
            "AWS S3",
            "AWS DynamoDB",
            "Shopify API",
            "AWS API Gateway",
            "AWS Cognito"
        ],
        "url": null,
        "images": [
            "https://i.ibb.co/dMwFDp9/exotictech1.png",
            "https://i.ibb.co/QvVrjDQ/exotictech2.png",
            "https://i.ibb.co/L0h25ZN/exotictech3.png",
            "https://i.ibb.co/CKKGRqm/exotictech4.png"
        ],
        "xs": 8
    },
    {
        "id": "14",
        "type": "Mobile App Development",
        "title": "No Scam Mobile App",
        "description": "No Scam is a mobile application that helps users to identify scam calls and messages. I have developed thr frontend for this project.",
        "client": "Solution365",
        "countryFlag": "https://www.countryflags.com/wp-content/uploads/sri-lanka-flag-png-large.png",
        "country": "Sri Lanka",
        "date": "2024",
        "technologies": [
            "React Native",
        ],
        "url": null,
        "images": [
            "https://i.ibb.co/qjcr0n9/Screenshot-2024-06-02-at-2-03-25-PM.png"
        ],
        "xs": 4
    },
    {
        "id": "1",
        "type": "Web Development",
        "title": "Digital Academy",
        "description": "Digital Academy is a Web Application that provides various courses on Computer Science and IT. It is a platform where students can learn online and get certified.",
        "client": "Digital Academy",
        "countryFlag": "https://www.countryflags.com/wp-content/uploads/sri-lanka-flag-png-large.png",
        "country": "Sri Lanka",
        "date": "2024",
        "technologies": [
            "ReactJS",
            "NodeJS",
            "Azure Functions",
            "Azure SQL Databases",
            "Azure SQL Server",
            "Azure Blob Storage"
        ],
        "url": "https://www.digitalacademy.lk/",
        "images": [
            "https://i.ibb.co/3SNJPBS/digitalacademy1.png",
            "https://i.ibb.co/7R7Pbs3/digitalacademy2.png",
            "https://i.ibb.co/ZL6z3tW/digitalacademy3.png",
            "https://i.ibb.co/hRGYvnd/digitalacademy4.png"
        ],
        "xs": 8
    },
    {
        "id": "2",
        "type": "Web Development",
        "title": "STEMUP Spaces",
        "description": "STEMUP Spaces is a Web Application that provides various courses on Science, Technology, Engineering and Mathematics. It is a platform where students can learn online and get certified.",
        "client": "STEMUP Education Foundation",
        "countryFlag": "https://www.countryflags.com/wp-content/uploads/sri-lanka-flag-png-large.png",
        "country": "Sri Lanka",
        "date": "2024",
        "technologies": [
            "ReactJS",
            "NodeJS",
            "Azure Functions",
            "Azure SQL Databases",
            "Azure SQL Server"
        ],
        "url": "https://spaces.stem.lk/",
        "images": [
            "https://i.ibb.co/q5N8KL3/spaces1.png",
            "https://i.ibb.co/w0nq0HH/spaces2.png",
            "https://i.ibb.co/VHsswS6/spaces3.png"
        ],
        "xs": 4
    },
    {
        "id": "4",
        "type": "Web Development",
        "title": "Micro:bit in Libraries",
        "description": "Micro:bit in Libraries is a Web Application that provides various courses on Micro:bit for students all around Sri Lanka. Admins can create courses, add lessons, quizzes, online meeting sessions, assignments. Students can enroll in courses, learn online and get certified.",
        "client": "STEMUP Education Foundation",
        "countryFlag": "https://www.countryflags.com/wp-content/uploads/sri-lanka-flag-png-large.png",
        "country": "Sri Lanka",
        "date": "2024",
        "technologies": [
            "ReactJS",
            "NodeJS",
            "Azure Functions",
            "Azure SQL Databases",
            "Azure SQL Server"
        ],
        "url": "https://microbitinlibraries.stem.lk/",
        "images": [
            "https://i.ibb.co/J5fZMK7/mbit1.png",
            "https://i.ibb.co/3hGbr24/mbit2.png",
            "https://i.ibb.co/pJkxNhh/mbit3.png"
        ],
        "xs": 4
    },
    {
        "id": "3",
        "type": "Web Development",
        "title": "Xternship Portal",
        "description": "Xternship Portal is a Web Application that connects students with companies for internships. It is a platform where students can apply for internships and companies can post their internships and hire students. After hiring, companies can track the progress of the students, evaluate them and provide feedback.",
        "client": "Xternship",
        "countryFlag": "https://www.countryflags.com/wp-content/uploads/sri-lanka-flag-png-large.png",
        "country": "Sri Lanka",
        "date": "2024",
        "technologies": [
            "ReactJS",
            "NodeJS",
            "Azure Functions",
            "Azure Cosmos DB for MongoDB",
            "Azure Blob Storage",
            "Email Communication Services Domain",
            "Azure Communication Service",
            "B2C Tenant",
            "App Service"
        ],
        "url": "https://xternship-frontend.vercel.app/",
        "images": [
            "https://i.ibb.co/MNbwthz/xtern1.png",
            "https://i.ibb.co/rdjSS4w/xtern2.png",
            "https://i.ibb.co/x2KyPBd/xtern3.png"
        ],
        "xs": 8
    },
    {
        "id": "6",
        "type": "Web Development",
        "title": "Yasalanka Physics",
        "description": "Yasalanka LMS offers physics education at your fingertips, ensuring ease of access from any device, whether you're on the go or at home.With our mobile-friendly platform, you can enjoy fast and convenient learning, making physics accessible whenever you choose.",
        "client": "Yasalanka Physics",
        "countryFlag": "https://www.countryflags.com/wp-content/uploads/sri-lanka-flag-png-large.png",
        "country": "Sri Lanka",
        "date": "2023",
        "technologies": [
            "ReactJS",
            "NodeJS",
            "Google Cloud Functions",
            "Google Firebase"
        ],
        "url": "https://www.yasalankaphysics.lk/",
        "images": [
            "https://i.ibb.co/zXQLrnr/yasalanka1.png",
            "https://i.ibb.co/gWmffPr/yasalanka2.png",
            "https://i.ibb.co/W2xKMsp/yasalanka3.png"
        ],
        "xs": 4
    },
    {
        "id": "7",
        "type": "Web Development",
        "title": "CoderDojo Sri Lanka",
        "description": "Voluntary contribution with Mr. Prabhath Mannapperuma to open source project of STEMUP Education Foundation through Github.",
        "client": "STEMUP Education Foundation",
        "countryFlag": "https://www.countryflags.com/wp-content/uploads/sri-lanka-flag-png-large.png",
        "country": "Sri Lanka",
        "date": "2023",
        "technologies": [
            "ReactJS",
            "SCSS",
            "Azure DevOps",
            "Azure App Service"
        ],
        "url": "https://coderdojo.lk/",
        "images": [
            "https://i.ibb.co/VgGf2R9/coderdojo1.png",
            "https://i.ibb.co/ZY1LrSY/coderdojo2.png",
            "https://i.ibb.co/ZdZvLDk/coderdojo3.png"
        ],
        "xs": 4
    },
    {
        "id": "8",
        "type": "Web Development",
        "title": "NEIC",
        "description": "The official website of Central Environmental Authority of Sri Lanka Government. The project by Airstudios. Role: Front End Developer",
        "client": "Central Environmental Authority - Sri Lanka.",
        "countryFlag": "https://www.countryflags.com/wp-content/uploads/sri-lanka-flag-png-large.png",
        "country": "Sri Lanka",
        "date": "2022",
        "technologies": [
            "HTML5",
            "CSS3"
        ],
        "url": "http://www.neic.cea.lk/",
        "images": [
            "https://i.ibb.co/vYK37mS/neic1.png",
            "https://i.ibb.co/XbgsgC4/neic2.png",
            "https://i.ibb.co/9HT8YgS/neic3.png"
        ],
        "xs": 4
    },
    {
        "id": "10",
        "type": "Web Development",
        "title": "Qonvo",
        "description": "A privacy protected Meeting Platform. This is my second year group project. Technologies - Deep Learning (classification) - Python Media Pipe",
        "client": "Open Source Project",
        "countryFlag": "https://www.countryflags.com/wp-content/uploads/sri-lanka-flag-png-large.png",
        "country": "Sri Lanka",
        "date": "2022",
        "technologies": [
            "HTML5",
            "CSS3",
            "Agora SDK",
            "Web RTC",
            "JavaScript"
        ],
        "url": "http://www.neic.cea.lk/",
        "images": [
            "https://i.ibb.co/vQrQwTg/qonvo1.png",
            "https://i.ibb.co/T1S545P/qonvo2.png",
            "https://i.ibb.co/DfrGFxV/qonvo3.png"
        ],
        "xs": 4
    },
    {
        "id": "11",
        "type": "Web Development",
        "title": "Weathreo",
        "description": "A weather forecast application is designed to provide accurate and up-to-date weather information in various locations to users.",
        "client": "Open Source Project",
        "date": "2022",
        "technologies": [
            "ReactJS",
            "CSS3",
            "Open Weather API",
            "GEO API"
        ],
        "url": "https://isw200.github.io/weatherapp/",
        "images": [
            "https://i.ibb.co/h993VZb/weathreo1.png",
            "https://i.ibb.co/TP2969Q/weathreo2.png"
        ],
        "xs": 4
    },
    {
        "id": "12",
        "type": "Web Development",
        "title": "3D Box",
        "client": "Open Source Project",
        "description": "A sample landing page website webpage using 3D models for 3D animation company.",
        "date": "2022",
        "technologies": [
            "ReactJS",
            "CSS3",
            "Spline"
        ],
        "url": "https://isw200.github.io/threed/",
        "images": [
            "https://i.ibb.co/68yqCJN/threed1.png",
            "https://i.ibb.co/s1zBXHR/threed2.png",
            "https://i.ibb.co/qs2z3nk/threed3.png"
        ],
        "xs": 4
    },
    {
        "id": "13",
        "type": "Computer Application",
        "title": "Consultation Management",
        "description": "A full stack application consultations management system using OOP concepts for a skin consultation centre.",
        "client": "Open Source Project",
        "date": "2021",
        "technologies": [
            "Java",
            "CSS3",
            "Java-Swing"
        ],
        "url": null,
        "images": [
            "https://i.ibb.co/wK6Lvhh/skincare.png"
        ],
        "xs": 4
    }
]

export default PROJECTS;
